import { Link, PageProps } from "gatsby";
import React, { useState } from "react";
import * as styles from "./footer.module.scss";
import { TranslationService } from "@/src/services/translation.service";

type FooterProps = {
  className?: string;
  pageContext: PageProps["pageContext"];
};

export default function Footer({ className, pageContext }: FooterProps) {
  const [translation] = useState(new TranslationService(pageContext));

  return (
    <div id="footer" className={`${className ? className : ""}`}>
      <div className={styles.footerTop}>
        <div className="inner">
          <h2 className={styles.footerTop__heading}> {translation.translate("footer.heading")}</h2>
          <h3 className={styles.footerTop__subHeading}>{translation.translate("footer.subHeading")}</h3>
          <div className={styles.footerTop__contact}>
            {translation.translate("footer.email")}: <a href="mailto:info@evidencediary.com">info@evidencediary.com</a>
            <br />
            {translation.translate("footer.phone")}: <a href="tel:+3584573443899">+358 4573443899</a>
          </div>
        </div>
      </div>

      <div className={styles.footerBot}>
        <div className={styles.footerBot__inner + " " + "inner"}>
          <div className={styles.footerBot__col}>
            <div>© Evidence Diary {new Date().getFullYear()}</div>
            <Link to={`${translation.linkPrefix}/terms-and-conditions`}>{translation.translate("menu.integrity")}</Link>
            <Link to={`${translation.linkPrefix}/privacy-policy`}>{translation.translate("menu.privacy_policy")}</Link>
          </div>
          <div className={styles.footerBot__col}>
            Web by <a href="https://winter.ax">Winter</a>
          </div>
        </div>
      </div>
    </div>
  );
}
